/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-13 20:40:31
 */
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as CloseButton } from "../../../icons/untitled-ui/closeXButton.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import { createTypography } from "src/theme/base/customTypography";
import { saveAs } from '../../../index'
import Button from '@mui/material/Button';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import IosShareIcon from '@mui/icons-material/IosShare';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useTeam } from "src/contexts/mtp-team-context";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useUser } from "src/hooks/use-user";
import { useDuration } from "src/contexts/mtp-duration-context";
// import toast from "react-hot-toast";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
export const DownloadPagePopUp = (props) => {
  const typography = createTypography();
  const navigate = useNavigate();
  const surveyId = props.surveyId;
  const fileName = props.fileName;
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const { selectedTeamId, selectedInviteId } = useTeam();
  const { selectedDuration } = useDuration();
  const { onClose, open = false, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');
  const handleClose = () => {
    onClose()
  };
  const currentHref = window.location.pathname;
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(link.sharedLink);
    toast.success('Link copied');
  };

  const handleMouseDownCopy = (event) => {
    event.preventDefault();
  };
  console.log('====================================');
  console.log("selectedInviteIdselectedInviteId", selectedInviteId);
  console.log('====================================');
  const getShareLink = async (type) => {
    console.log("typetype",type);
    
    try {
      setIsLoading(true);
      let json;
      if (type === "csv") {
        if (currentHref === "/mtporgnarrative") {
          json = await mtpDashboardResultsApi.getORGJsonData(
            accessToken,
            currentHref === "/mtporgnarrative" ? [] : selectedTeamId
          )
        } else {
          json = await mtpDashboardResultsApi.getJsonData(
            accessToken,
            currentHref === "/mtporgnarrative" ? [] : selectedTeamId
          )
        }
      }
      if (type === "html" && currentHref === "/MTP") {
        const response = await mtpDashboardResultsApi.postMTPInviteShareLInk(
          {
            "inviteId": selectedTeamId.length === 1 ? selectedInviteId[selectedInviteId.length - 1] : selectedInviteId,
            "teamId": selectedTeamId,
            "selectedDuration": selectedDuration

          },
          6,
          accessToken
        )
        setLink(response === false ? null : response);
      } else {
        if (type === "pdf" && currentHref === "/mtporgnarrative") {
          
          navigate(`/downloadmtporgnarrative`);
        }else{
          const response = await saveAs(
            surveyId,
            type,
            fileName,
            json
          )
          console.log('====================================');
          console.log("responnnse", response);
          console.log('====================================');
          setLink(response === false ? null : response);
        }
        
      }



      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Dialog fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      {...other}>

      <DialogTitle
        justifyContent="space-between"
        alignItems="center">
        <Typography sx={{ ...typography.cardHeader.regular, fontSize: 24 }}>
          {fileName && fileName}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseButton />
      </IconButton>
      <Divider sx={{ backgroundColor: '#000000' }} />
      {(isLoading) &&
        (<>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
        )}
      <DialogContent>
        <Stack p={0}
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          flex={1}
        >
          <Button
            color="primary"
            size="medium"
            variant="text"
            onClick={() => getShareLink("pdf")}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              paddingLeft: "12px",
            }}
            startIcon={<SimCardDownloadIcon />}
          >
            Download as PDF
          </Button>
          {
            (user.roleId === 1000 || user.roleId === 104) &&
            <Button
              color="primary"
              size="medium"
              variant="text"
              onClick={() => getShareLink("csv")}
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                textAlign: "left",
                paddingLeft: "12px",
              }}
              startIcon={<SimCardDownloadIcon />}
            >Download raw Excel data</Button>
          }

          <Button
            color="primary"
            size="medium"
            variant="text"
            onClick={() => getShareLink("html")}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              paddingLeft: "12px",
            }}
            startIcon={<IosShareIcon />}
          >Share as link</Button>
          {link &&
            <FormControl sx={{ m: 1, width: "100%", }}
              variant="outlined">
              <OutlinedInput
                id="standard-adornment-password"
                type='text'
                disabled
                defaultValue={link && link.sharedLink}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleCopyToClipboard}
                      onMouseDown={handleMouseDownCopy}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          }
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
