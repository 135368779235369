/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-11-22 09:13:02
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { tokens } from "src/locales/tokens";
// import { paths } from "src/paths";
import { ReactComponent as TeamPulse } from "../../icons/untitled-ui/teamPulse.svg";
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
export const useSections = () => {
  const { t } = useTranslation();
  const user = useUser();
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  console.log("surveyPermssurveyPerms",JSON.parse(surveyPerms).map(Number).includes(6));
  return useMemo(() => {
    const sections = [
      {
        subheader: t(tokens.nav.menu),
        items: [
          {
            title: t(tokens.nav.home),
            path: '/leadSelf?navigated=true',
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },

        ],
      },
    ];
    if (dashboardPerms && JSON.parse(dashboardPerms).includes(14)) {
      sections[0].items.push({
        title: "Team",
        path: '/teamDiversity?navigated=true',
        icon: (
          <SvgIcon fontSize="small">
            <BarChartSquare02Icon />
          </SvgIcon>
        ),
      });
    }
    // Check if dashboardPerms includes the required value (7)
    if (surveyPerms && JSON.parse(surveyPerms).includes(6)) {
      sections[0].items.push({
        title: t(tokens.nav.myMojo),
        path: '/MTP?navigated=true',
        icon: (
          <SvgIcon fontSize="small">
            <TeamPulse />
          </SvgIcon>
        ),
      });
    }

    return sections;
  }, [t,dashboardPerms,surveyPerms]);
};
