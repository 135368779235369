/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-08-19 15:53:36
 */
import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeaderWhite.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import SurveyTile from "./surveys/surveyCards";
import MyMojoSurveyTile from "./myMojoComponentss/surveyCards";
import { RouterLink } from "src/components/router-link";
// import surveyAPIService from "src/services/surveyApi";
import surveyAPIService from "src/services/surveyApi";
import { useState } from "react";
import { createTypography } from "src/theme/base/customTypography";
import loginApiService from "src/services/loginAPI";
import * as SldButtons from "src/theme/base/SldButtons";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import { useLocation } from "react-router";
import LinkExpired from "../wiredUpSurveys/surveys/linkExpire";
// import { createTypography } from "../../theme/base/customTypography";



function MyMojoStartPageLayout(props) {
  const { children } = props;
  // const id = 7;
  const token = "tokenuser";
  // const sectionPage = 1;   

  const accesssToken = sessionStorage.getItem("accessToken");

  const queryParameters = new URLSearchParams(window.location.search);
  const [inviteId, setInviteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const typography = createTypography();
  const [emailToken, setEmailToken] = useState(queryParameters.get("token"));
  const [emailInviteId, setEmailInviteId] = useState(queryParameters.get("inviteId"));
  const [surveyId, setSurveyId] = useState(queryParameters.get("surveyId"));
  const [valid, setValid] = useState(0);
  const [tenantId,setTenantId] = useState(null)
  const location = useLocation();

  // const user = useUser();
  console.log('emailtoken', emailToken);
  console.log('email Invite id', emailInviteId);

  /**
   * Function to get invite id , If no invite id is present new invite id will be created
   * @date 11/5/2023 - 12:07:16 AM
   *
   * @async
   * */
  const getInviteId = async () => {
    try {
      const inviteIdGetData = {
        survey_id: surveyId,
        inviteId: inviteId,
        timeStamp: "",
        teamId: "",
      };
      //api call to check invite id
      const response = await surveyAPIService.checkInviteId(
        inviteIdGetData,
        accesssToken
      );
      console.log("invite id", response);
      setInviteId(response.data.inviteId);
      setIsLoading(false)
    } catch (error) { }
  };
  useEffect(() => {
    if (location.search.substring(1) && !queryParameters.get("surveyId")) {
      const base64String = location.search.substring(1);
      console.log("base64 string", base64String);

      // Decode the base64 string to get the original string
      const decodedString = atob(base64String);
      console.log("decoded string", decodedString);

      // Split the decoded string to get individual parameters
      const paramsArray = decodedString.split("&");
      console.log("--------params array-------", paramsArray);

      // Create an object to store the parameters
      const params = {};

      // Populate the params object
      paramsArray.forEach((param) => {
        const equalSignIndex = param.indexOf("=");
        const key = param.slice(0, equalSignIndex);
        const value = param.slice(equalSignIndex + 1);
        params[key] = value;
        console.log("each param:", key, value);
      });
      // Set your state variables accordingly
      setEmailInviteId(params.inviteId);
      setEmailToken(params.token);
      setSurveyId(params.surveyId);
      setTenantId(params?.tenantId)
      // setInvitedUserName(params.invitedUser);
      // setTenantId(params.tenantId);
    } else {
      getInviteId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  const validateToken = async () => {
    try {
      console.log('tokkenn validator called', token);
      const response = await loginApiService.decodeToken(emailToken);
      console.log('token fetched', response);
      if (response?.userId) {
        const stringifiedUser = JSON.stringify(response)
        sessionStorage.setItem("userDetails", stringifiedUser);
        sessionStorage.setItem("accessToken", emailToken)
        setInviteId(emailInviteId);
      }


    } catch (error) {

    }
  }
  // useEffect(() => {
  //   if(emailInviteId === null){
  //     getInviteId();

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const validateLink = async () => {
    try {
      console.log('inviteId', inviteId);
      const response = await loginApiService.validateLink(emailToken, inviteId, 9);
      console.log('response fetched', response);
      if (response.length > 0) {
        setValid((response[response.length - 1].active !== 0 && response[response.length - 1].is_complete === 0) ? 0 : 1);
      }
      setIsLoading(false)
    } catch (error) {

    }
  }
  useEffect(() => {
    if (inviteId && emailInviteId && emailToken) {
      validateLink();
    }
    // eslint-disable-next-line
  }, [inviteId, emailInviteId, emailToken])
  useEffect(() => {
    if (emailInviteId && emailToken) {
      console.log('---validate token use effect', emailInviteId, emailToken);

      validateToken()

    }
    // else{
    //   getInviteId()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailInviteId, emailToken])

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  console.log("valid", valid);
  if (valid === 1) {
    return <LinkExpired />;
  }
  return (
    <SldSurveyCard.SurveyGrid
      container
      spacing={0}

    >
      <SldSurveyCard.SurveyGridItem
        item
        lg={12}
        sm={12}
      >
        <SldSurveyCard.SurveyLogo>
          <SmartLeaderLogo width='250' />
        </SldSurveyCard.SurveyLogo>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          spacing={6}

        >
          <Typography sx={typography.qNheading}>
            My Mojo Questionnaire
          </Typography>
          {/* <Typography
           sx={typography.qNTitle}
          >
            Only <span style={{ fontWeight: 600 }}>15 minutes </span>to get a
            description of who you are and why you do things the way you do.
          </Typography> */}
          <SldButtons.SurveyClock
            variant="contained">
            <AccessTimeIcon />
            <Typography variant="subtitle1"> Duration <span>15 mins</span></Typography>
          </SldButtons.SurveyClock>

        </Stack>
      </SldSurveyCard.SurveyGridItem>
      <SldSurveyCard.SurveyStack
        spacing={3}

        sx={{
          marginTop: { lg: '-140px', sm: '5px', xs: '3px' },
          padding: { lg: '0 60px', md: '0 60px', sm: '0 0', xs: '0' },
        }}
      >
        <MyMojoSurveyTile />
        <SldSurveyCard.SurveyRules
          lg={12}
        >
          <SldSurveyCard.SurveyContainer>{children}</SldSurveyCard.SurveyContainer>
          {/* </SurveyStack> */}

          <SldButtons.WhiteButtonCap endIcon={<ArrowForwardIcon />}
            variant="contained"

            component={RouterLink}
            to={`/surveyQuestions?id=${surveyId}&inviteId=${inviteId}${tenantId!=null ? `&tenantId=${tenantId}` : ''}`}
          >
            <Typography sx={typography.startButton}>Start</Typography>
          </SldButtons.WhiteButtonCap>
        </SldSurveyCard.SurveyRules >

      </SldSurveyCard.SurveyStack>
    </SldSurveyCard.SurveyGrid>
  );
}

export default MyMojoStartPageLayout;
