/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-13 20:40:54
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
// joyride
import Joyride, { STATUS } from 'react-joyride';
import React, { useState, useEffect, useContext } from 'react';
// eslint-disable-next-line
import CryptoJS from 'crypto-js';


import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Stack, Divider } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDuration } from "src/contexts/mtp-duration-context";
import * as SldButtons from "src/theme/base/SldButtons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
import CustomTooltip from 'src/components/CustomTooltipJoyridenew';
// joyride
//import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";
// import { saveAsHtmlFile } from "../../../index";
import { DownloadPagePopUp } from "../../MTP Dashboard/popUps/downloadPagePopUp";
import { JoyrideContext } from 'src/contexts/JoyrideContext';
import { useTeam } from "src/contexts/mtp-team-context";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { useUser } from "src/hooks/use-user";
import HRAnalyticsApiService from "src/services/hrAnalyticsApi";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
import customerApiService from "src/services/customerApi";
import loginApiService from "src/services/loginVerifyAPI";
import { getJoyrideSteps } from 'src/components/joyrideStepsMTPdb';
import MTPApiService from "src/services/mtpAPI";

// joyride
import 'src/components/joyride.css';

const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state


export const TopNav = (props) => {
  // eslint-disable-next-line
  const { joyrideSteps, addSteps, removeSteps } = useContext(JoyrideContext); // Access context
  const [teamIds, setTeamIds] = useState(null);
  const [userAccess, SetUserAccess] = useState(true);
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);

  const location = useLocation();
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { isMinimized } = useSidebar();
  const [isLocked, setIsLocked] = React.useState(true);
  const [tenantId, setTenantId] = useState(null);

  const { selectedDuration, setDuration } = useDuration();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { applyTeams, selectedTeamId, dashboardStatusState, hrAnalyticsStatusState } = useTeam();
  const [teamLeader, setTeamLeader] = useState(0);
  const accessToken = sessionStorage.getItem("accessToken");

  // eslint-disable-next-line  
  const getPermissions = async () => {
    await customerApiService.getJoyride(accessToken, user.id);
    await loginApiService.getDashboardIds(accessToken, user.id);
    await loginApiService.getSurveyIds(accessToken, user.id);

  };
  const [isTitledClassAdded, setIsTitledClassAdded] = useState(false);
  const [isAccClassAdded, setIsAccClassAdded] = useState(false);

  console.log("dashboardStatusState", dashboardStatusState, "hrAnalyticsStatusState", hrAnalyticsStatusState);


  const openPopup = () => {
    setPopupOpen(true);
    setIsTitledClassAdded(true);
    setIsAccClassAdded(true);


  };
  const closePopup = () => {
    setPopupOpen(false);
    setIsTitledClassAdded(false);
    setIsAccClassAdded(false);

  };
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const user = useUser();
  const isAdmin = (parseInt(user.roleId) === 1000 || parseInt(user.roleId) === 104) ? true : false;

  // const [alignment, setAlignment] = useState('monthly');
  const currentHref = window.location.pathname;
  const handleChange = (event, newAlignment) => {
    setDuration(newAlignment);
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log("togglebutton", selectedTeamId);
  useEffect(() => {

    fetchLeaderStatus();
    // eslint-disable-next-line
  }, [selectedTeamId]);
  const fetchLeaderStatus = async () => {
    try {
      let data = await HRAnalyticsApiService.checkTeamLeader(accessToken, selectedTeamId);
      if (data) {
        setTeamLeader(data.team_leader);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    if (isJoyrideRunning) {
      document.body.classList.add('joyride-active');
    } else {
      document.body.classList.remove('joyride-active');
    }
  }, [isJoyrideRunning]);
  useEffect(() => {
    const elements = document.querySelectorAll(".tilted");
    elements.forEach(element => {
      if (isTitledClassAdded) {
        element.classList.add("downloaded");
      } else {
        element.classList.remove("downloaded");
      }
    });
  }, [isTitledClassAdded]);

  useEffect(() => {
    // Remove classes first to avoid duplication
    document.body.classList.remove('mtpSettings-url')

    if (location.pathname === '/mtpSettings') {
      document.body.classList.add('mtpSettings-url');
    }
  }, [location]);

  useEffect(() => {
    const elements = document.querySelectorAll(".MuiAccordion-root ");
    elements.forEach(element => {
      if (isAccClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isAccClassAdded]);
  // joyride
  const [run, setRun] = useState(false);
  // eslint-disable-next-line
  const [stepIndex, setStepIndex] = useState(0);
  const [disableScroll, setDisableScroll] = useState(false);
  const [steps, setSteps] = useState([]);
  // eslint-disable-next-line
  const [triggered, setTriggered] = useState(true);
  useEffect(() => {
    // Clear existing steps
    removeSteps();
    console.log("location.pathname", location.pathname);

    // Retrieve new steps based on the current pathname
    const newSteps = getJoyrideSteps(location.pathname, tenantId);
    console.log("newSteps", newSteps);

    // If there are new steps, add them and update the component's steps
    if (newSteps && newSteps.length > 0) {
      setTimeout(() => addSteps(newSteps), 100);
      // No need to use setSteps(newSteps) immediately here
      console.log("Added new steps", newSteps);
    } else {
      // Clear the component's steps if there are no new steps
      setSteps([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, addSteps, removeSteps]);

  console.log('====================================');
  console.log("stepss", steps);
  console.log('====================================');

  useEffect(() => {
    const joyrideSteps = getJoyrideSteps(location.pathname, tenantId);
    if (joyrideSteps?.length > 0) {
      setSteps(joyrideSteps);
      console.log("joyrideSteps", joyrideSteps);
    } else {
      // Optionally handle the case where joyrideSteps is empty
      setSteps([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, joyrideSteps]);




  const handleClickStart = (event, callback) => {
    event.preventDefault();

    // Scroll to the top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Delay the filtering and setting of steps
    setTimeout(() => {
      const filteredSteps = steps.filter((step) => document.querySelector(step.target));

      if (filteredSteps.length > 0) {
        setSteps(filteredSteps);
        setRun(true);
      } else {
        console.warn('No valid targets found for Joyride steps.');
      }

      // Invoke the callback if provided
      if (callback && typeof callback === 'function') {
        callback();
      }
    }, 1000);
  };
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = dashboardPermsEn && JSON.parse(CryptoJS.AES.decrypt(dashboardPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);
  const joyrideStatusEn = sessionStorage.getItem("joyrideStatus");
  const joyrideStatus = CryptoJS.AES.decrypt(joyrideStatusEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);

  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = JSON.parse(CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);
  const getTeamsHavingValidation = async () => {
    try {

      const response = await MTPApiService.getTeamsHavingPermission(accessToken);
      console.log("----topnav---", response);
      if (response.data.length === 0) {
        console.log("----teams with permission if topnav", setIsLocked);
        setIsLocked(true);
        setTriggered(false)
      } else {
        setIsLocked(false);
        setTriggered(true)
        console.log("----teams with permission--- setIsLocked", setIsLocked);

      }

    } catch (error) {
      // Handle error

    }
  };
  useEffect(() => {
    getTeamsHavingValidation()
    // eslint-disable-next-line
  }, [])

  const checkPermission = async () => {
    try {
      console.log('--check permission called----');
      const response = await MTPApiService.checkPermission(accessToken, teamIds);
      console.log('----response----- mtpsettings', response);
      SetUserAccess(response.userAccess)

    } catch (error) {

    }
  }
  useEffect(() => {
    if (teamIds) {
      if (!isAdmin && parseInt(tenantId) !== 0)
        checkPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamIds, isAdmin, tenantId])

  useEffect(() => {
    // Parse the query string to extract the data parameter
    const queryParameters = new URLSearchParams(window.location.search);

    const encodedTeamsId = queryParameters.get("teamsId");
    if (encodedTeamsId) {
      // Decode the encoded array
      const decodedTeamsId = JSON.parse(decodeURIComponent(encodedTeamsId));
      setTeamIds(decodedTeamsId);
    }


    const encodedTenantId = queryParameters.get("tenantId");
    console.log("---encoded tenant id", encodedTenantId);
    setTenantId(encodedTenantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  console.log("teams id", teamIds);
  useEffect(() => {
    console.log('useeffect checkand trigger mtp/hra');

    const checkAndTriggerJoyride = async () => {
      console.log('---function called----');
      const dashboardDiv = document.getElementById('topNavWidth'); // Replace with the actual ID or class

      if (dashboardDiv) {
        dashboardDiv.classList.add('no-htb'); // Add class
        console.log('Class added:', dashboardDiv.classList);
      } else {
        console.error('dashboardDiv not found');
      }
      console.log('---joyrideStatus steps--', steps);
      if (steps.length > 0) {

        console.log('---joyrideStatus steps--', joyrideStatus);
        console.log('---surveyPerms steps--', surveyPerms);

        const currentPath = window.location.pathname;
        let htw = false; // default value
        dashboardDiv.classList.add('no-htb');
        console.log('dashboardPerms:', dashboardPerms);
        console.log(dashboardStatusState, 'dashboardStatusState insidecheck ')
        // Set htw based on the current URL path
        if (currentPath === '/MTP') {
          console.log('---inside MTP if---');
          dashboardDiv.classList.add('no-htb');

          // Check for permission 
          if (surveyPerms && surveyPerms.includes(6)) {


            htw = dashboardStatusState && JSON.parse(joyrideStatus).MTP === 0;
            console.log('---inside MTP if dashboardStatusState--', dashboardStatusState)
            console.log('---inside MTP if joyrideStatus--', joyrideStatus)
            if (dashboardStatusState && JSON.parse(joyrideStatus).MTP === 1) {

              dashboardDiv.classList.remove('no-htb');
            } else {
              dashboardDiv.classList.add('no-htb');
            }

          }

        } else if (currentPath === '/hranalytics') {

          dashboardDiv.classList.add('no-htb');

          // Check for permission 
          if (surveyPerms && surveyPerms.includes(6)) {
            console.log('---inside HRA if---');

            htw = hrAnalyticsStatusState && JSON.parse(joyrideStatus).hranalytics === 0;
            if (hrAnalyticsStatusState && JSON.parse(joyrideStatus).hranalytics === 1) {

              dashboardDiv.classList.remove('no-htb');
            } else {
              dashboardDiv.classList.add('no-htb');
            }
          }

          else if (isAdmin) {
            console.log('---inside HRA if---');

            htw = hrAnalyticsStatusState && JSON.parse(joyrideStatus).hranalytics === 0;
            if (hrAnalyticsStatusState && JSON.parse(joyrideStatus).hranalytics === 1) {

              dashboardDiv.classList.remove('no-htb');
            } else {
              dashboardDiv.classList.add('no-htb');
            }
          }
        }

        console.log('htwss:', htw);


        if (htw === true) {
          handleClickStart(new Event('click'));
          dashboardDiv.classList.remove('no-htb');
          console.log('Triggering Joyride...');
        }

        setTriggered(false);
      }

    };




    checkAndTriggerJoyride();

    // eslint-disable-next-line 
  }, [window.location.pathname, dashboardStatusState, hrAnalyticsStatusState, joyrideStatus]);


  useEffect(() => {
    const checkAndTriggerJoyride = async () => {
      console.log('--- settings function called----', isLocked, triggered, steps.length);
      const dashboardDiv = document.getElementById('topNavWidth'); // Select the div 

      if (steps.length > 0 && triggered) {

        console.log('---joyrideStatus--', joyrideStatus);
        console.log('---surveyPerms--', surveyPerms);

        const currentPath = window.location.pathname;
        let htw = false; // default value
        // Set htw based on the current URL path
        if (currentPath === '/mtpSettingsTeamSelect') {
          dashboardDiv.classList.add('no-htb');
          console.log('currentPath', currentPath);
          console.log('isLocked', isLocked);

          // Check for permission 
          if (!isLocked && surveyPerms && surveyPerms.includes(6)) {

            dashboardDiv.classList.remove('no-htb');
            console.log('---inside MTP settings ts---');
            htw = JSON.parse(joyrideStatus).mtpSettingsTeamSelect === 0;
            console.log('jy stauts', JSON.parse(joyrideStatus).mtpSettingsTeamSelect)

            console.log('htwss: ss111', htw);
            if (JSON.parse(joyrideStatus).mtpSettingsTeamSelect === 1) {
              dashboardDiv.classList.remove('no-htb');

            }


            console.log('htwss: ss', htw);
          }

          else if (isAdmin) {
            dashboardDiv.classList.remove('no-htb');
            console.log('---inside MTP settings admin ts---');
            htw = JSON.parse(joyrideStatus).mtpSettingsTeamSelect === 0;
            console.log('jy stauts', JSON.parse(joyrideStatus).mtpSettingsTeamSelect)

            console.log('htwss: ss111', htw);
            if (JSON.parse(joyrideStatus).mtpSettingsTeamSelect === 1) {
              dashboardDiv.classList.remove('no-htb');

            }


            console.log('htwss: ss', htw);
          }
        }
        else if (currentPath === '/mtpSettings') {
          dashboardDiv.classList.add('no-htb');

          // Check for permission 
          if (userAccess && surveyPerms && surveyPerms.includes(6)) {
            console.log('---inside MTP settingsss--', JSON.parse(joyrideStatus).mtpSettings);
            dashboardDiv.classList.remove('no-htb');

            htw = JSON.parse(joyrideStatus).mtpSettings === 0;
            console.log(JSON.parse(joyrideStatus).mtpSettings, 'JSON.parse(joyrideStatus).mtpSettings')

            if (JSON.parse(joyrideStatus).mtpSettings === 1) { dashboardDiv.classList.remove('no-htb'); }


          }


        }

        console.log('htwss:', htw);


        if (htw === true) {
          handleClickStart(new Event('click'));
          dashboardDiv.classList.remove('no-htb');
          console.log('Triggering Joyride...');
        }

        setTriggered(false);
      }

    };

    checkAndTriggerJoyride();

    // eslint-disable-next-line 
  }, [steps, window.location.pathname, isLocked]);
  useEffect(() => {
    setTriggered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, dashboardStatusState, hrAnalyticsStatusState,])
  // joyride
  const handleJoyrideCallback = (data) => {
    const { status, index } = data;

    if (status === 'running') {
      setIsJoyrideRunning(true);
    } else {
      setIsJoyrideRunning(false);
    }
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }

    setStepIndex(index);

    // Disable scrolling for a particular step (example: step 2)
    const scrollBehavior = {
      '/hranalytics': {
        enableScrollSteps: [1, 2, 3, 4],
        disableScrollSteps: [0,],
      },
      'MTP': {
        enableScrollSteps: [1],
        disableScrollSteps: [0, 2, 3, 4],
      },
      '/mtpSettings': {
        enableScrollSteps: [1, 2, 3, 4, 5, 6],
        disableScrollSteps: [0],
      },
      '/mtpSettingsTeamSelect': {
        enableScrollSteps: [1],
        disableScrollSteps: [0],
      },
      // Add more URLs and their respective steps as needed
    };

    const currentScrollBehavior = scrollBehavior[location.pathname] || {};

    if (currentScrollBehavior.enableScrollSteps?.includes(index)) {
      setDisableScroll(false);
    } else if (currentScrollBehavior.disableScrollSteps?.includes(index)) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false); // Default behavior if not specified
    }
  };
  const calculateScrollOffset = () => {
    switch (location.pathname) {
      case '/hranalytics':
        return 250;
      case '/MTP':
        return 200;
      case '/mtpSettings':
        return 200;
      case '/mtpSettingsTeamSelect':
        return 200;
      default:
        return 0;
    }
  };
  const currentScrollOffset = calculateScrollOffset();
  // joyride

  return (
    <Box
      component="header"
      id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        paddingRight: '0',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        transition: 'width 0.3s ease, left 0.3s ease',
        '&.no-htb #downloadButton1': { display: 'none' },
        left: {
          lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,

        },
        '&.no-permision #downloadButton1': { display: 'none' },

        top: 0,
        width: {

          lg: `calc(100% - ${isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 6,
          marginBottom: 1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography sx={typography.profileHeaing}>
            {currentHref === "/mtporgnarrative"
              ? "Organisation Narratives"
              : isMobile
                ? "MTP"
                : "MyTeamPulse"}
          </Typography>
          {window.location.pathname === "/MTP" && selectedTeamId.length > 1 && (
            <ToggleButtonGroup
              value={selectedDuration}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              className="joyride-target-3"
            >
              <ToggleButton value="monthly"
                style={{ color: "#00008B" }}>
                Monthly
              </ToggleButton>
              <ToggleButton value="quarterly"
                style={{ color: "#00008B" }}>
                Quarterly
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>
          {((!selectedTeamId.length === 0 || applyTeams || Number(user.tenetId) === 0) || currentHref === "/hranalytics" || currentHref === "/mtporgnarrative") && (teamLeader === 1 || user.roleId?.toString() === "1000" || user.roleId?.toString() === "104" || user.tenetId === 0 || user.roleId?.toString() === "1001") && (
            //roleid 1000 is for MTP super admin
            <SldButtons.WhiteButtonSIcon sx={{ width: '100%' }}
              className="joyride-target-2"
              id="downloadButton"
              variant="outlined"
              onClick={openPopup}
              startIcon={<DownloadPageIcon />}
            >
              <span>{isMobile ? null : 'Download Page'}</span>
            </SldButtons.WhiteButtonSIcon>
          )}

          {/* <NotificationsButton /> */}
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"
          spacing={0}>
          {/* joyride */}
          <IconButton id="downloadButton1"
            onClick={handleClickStart}>
            <HelpOutlineIcon />
          </IconButton>


          <>

            <Joyride
              callback={handleJoyrideCallback}
              continuous
              run={run}
              scrollToFirstStep
              disableOverlayClose={true}
              disableCloseOnEsc={true}
              showProgress
              showSkipButton
              scrollOffset={currentScrollOffset}
              steps={steps}
              disableScrolling={disableScroll}
              tooltipComponent={CustomTooltip}
              disableScrollParentFix={true}


              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
            />
          </>
          {/* joyride */}
          <IconButton id="downloadButton2"
            onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />

        </Stack>

      </Stack><Divider sx={{ mx: 6 }} />
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}
        fileName={"MyTeamPulse"} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
